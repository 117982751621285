import {
  LayoutContent as baseLayoutText,
  getLocalizedContent,
} from "@onzeit/shared";
import type { MetaFunction } from "@remix-run/cloudflare";
import { Form, Outlet, useLocation, useParams } from "@remix-run/react";
import { Layout } from "~/components/frontend";

export const meta: MetaFunction = () => {
  return [
    { title: "Onzeit - Das smarte Buchungstool für mobile DienstleisterInnen" },
    {
      name: "description",
      content: "Onzeit - Das smarte Buchungstool für mobile DienstleisterInnen",
    },
  ];
};

export default function Index() {
  const pathName = useLocation().pathname;
  const locale = useParams().locale;
  const layoutText = getLocalizedContent(locale!, baseLayoutText);
  return (
    <Layout pathName={pathName} text={layoutText} Form={Form} locale={locale!}>
      <Outlet />
    </Layout>
  );
}
